/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-27",
    versionChannel: "nightly",
    buildDate: "2024-06-27T00:06:10.768Z",
    commitHash: "c6ed64cced8f5f5f3d62c41fa9197c060067cfd0",
};
